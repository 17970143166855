

































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { apiRegisterConfig, apiRegisterConfigSet } from '@/api/setting/user'
import MaterialSelect from '@/components/material-select/index.vue'
@Component({
  components: {
    MaterialSelect,
  },
})
export default class LoginRegister extends Vue {
  // 图片
  images = {
    avatar: require('@/assets/images/setting/img_shili_mine_touxiang.png'),
  }

  /** S Data **/
  form = {
    default_avatar: '', // 默认头像
    register_way: [], // 注册方式：1-手机号码注册（注册设置必传）
    login_way: [], //登录方式：1-账号密码登录；2-手机短信验证码登录
    is_mobile_register_code: 0, // 手机号码注册需验证码：0-关闭；1-开启
    coerce_mobile: 0, // 手机号码注册需验证码：0-关闭；1-开启
    h5_wechat_auth: 0, // 微信公众号-微信授权登录：0-关闭；1-开启
    h5_auto_wechat_auth: 1, // 微信公众号-自动微信授权登录:0-关闭；1-开启;
    mnp_wechat_auth: 0, // 小程序-微信授权登录 :0-关闭；1-开启;
    mnp_auto_wechat_auth: 1, // 小程序-自动微信授权登录:0-关闭；1-开启;
    app_wechat_auth: 0, // app-微信授权登录:0-关闭；1-开启;
    scene: '', // 场景：user-用户设置；register-注册设置；withdraw-提现设置
  }
  /** E Data **/

  @Watch('form.h5_wechat_auth')
  h5WechatAuthWatch(val: any) {
    if (val == 0) {
      this.form.h5_auto_wechat_auth = 0
    }
  }

  @Watch('form.h5_auto_wechat_auth')
  h5AutoWechatAuthWatch(val: any) {
    if (val == 1) {
      this.form.h5_wechat_auth = 1
    }
  }

  @Watch('form.mnp_wechat_auth')
  mnpWechatAuthWatch(val: any) {
    if (val == 0) {
      this.form.mnp_auto_wechat_auth = 0
    }
  }

  @Watch('form.mnp_auto_wechat_auth')
  mnpAutoWechatAuthWatch(val: any) {
    if (val == 1) {
      this.form.mnp_wechat_auth = 1
    }
  }

  // 获取用户设置
  getLoginRegister() {
    apiRegisterConfig()
      .then((res: any) => {
        // this.$message.success('数据请求成功!')
        this.form = res
      })
      .catch(() => {
        // this.$message.error('数据请求失败!')
      })
  }

  // 修改用户设置登录注册
  setLoginRegister() {
    this.form.scene = 'register' // 场景：user-用户设置；register-注册设置；withdraw-提现设置
    return apiRegisterConfigSet(this.form)
      .then((res: any) => {
        // this.$message.success('保存成功!')
        setTimeout(() => {
          this.getLoginRegister()
        }, 50)
      })
      .catch(() => {
        // this.$message.error('保存失败!')
      })
  }

  /** S Life Cycle **/
  created() {
    this.getLoginRegister()
  }
  /** E Life Cycle **/
}
